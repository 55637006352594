import { FC, useContext, useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { Context } from "../../index";

import style from "../../css/header_css/HeaderForm.module.css";

import logo from "../../img/logo_img/logo.svg";
import education from "../../img/main_img/education.svg";
import business from "../../img/main_img/business.svg";
import task from "../../img/main_img/task_icon.svg";
import rooms from "../../img/main_img/rooms_icon.svg";
import avatar from "../../img/main_img/avatar.svg";
import { BsFillPersonFill, BsGiftFill, BsBoxArrowRight } from "react-icons/bs";

const HeaderForm: FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { authStore, globalStore, profileStore } = useContext(Context);

  const menuRef = useRef(null);

  const useClickOutside = (ref: React.MutableRefObject<any>, callback: any) => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    });
  };

  useClickOutside(menuRef, () => {
    if (isOpen) setTimeout(() => setOpen(false), 50);
  });

  return (
    <header>
      <div className={style.header_wrapper}>
        <div className={style.header_left}>
          <div>
            <img
              className={style.logo}
              src={logo}
              onClick={() => globalStore.setFalseALL()}
              alt="logo"
            />
          </div>
          <div
            className={`${style.icon_container} ${style.room_ico}`}
            onClick={() => globalStore.setFalseALL()}
          >
            <img src={rooms} alt="rooms" />
            <p>Комнаты</p>
          </div>
          <div
            className={`${style.icon_container} ${style.task}`}
            onClick={() => {
              globalStore.setFalseALL();
              globalStore.setCategoriesForm(true);
            }}
          >
            <img src={task} alt="task" />
            <p>Таски</p>
          </div>
          <div className={`${style.icon_container} ${style.education}`}>
            <img src={education} alt="education" />
            <p>Образование</p>
          </div>
          <div className={`${style.icon_container} ${style.business}`}>
            <img src={business} alt="business" />
            <p>Для бизнеса</p>
          </div>
        </div>
        {authStore.isAuth ? (
          <div className={style.header_right}>
            <p
              className={style.username}
              onClick={() => {
                globalStore.setFalseALL();
                globalStore.setProfileForm(true);
              }}
            >
              {authStore.user.username}
            </p>
            <img
              src={profileStore.isMyImg ? profileStore.isMyImg : avatar}
              className={style.avatar}
              alt="img"
              onClick={() => setOpen(!isOpen)}
            />
            <nav
              className={`${style.menu} ${isOpen ? style.menu_active : ""}`}
              ref={menuRef}
            >
              <ul className={style.menu__list}>
                <li
                  className={style.menu__item}
                  onClick={async () => {
                    globalStore.setFalseALL();
                    globalStore.setProfileForm(true);
                    await profileStore.getProfile();
                  }}
                >
                  <BsFillPersonFill className={style.icon} />
                  <span>Профиль</span>
                </li>
                <li
                  className={style.menu__item_gold}
                  onClick={() => {
                    globalStore.setFalseALL();
                    globalStore.setRatingForm(true);
                  }}
                >
                  <BsGiftFill className={style.icon} />
                  <span>Рейтинг</span>
                </li>
                <li
                  className={style.menu__item_red}
                  onClick={async () => {
                    globalStore.setFalseALL();
                    authStore.logout();
                  }}
                >
                  <BsBoxArrowRight className={style.icon_red} />
                  <span>Выход</span>
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <div className={style.header_right}>
            <p
              className={style.auth}
              onClick={() => {
                globalStore.setFalseALL();
                globalStore.setAuthForm(true);
                globalStore.setAuthFooterForm(true);
              }}
            >
              Авторизоваться
            </p>

            <div
              className={`${style.header_join_btn} ${style.connect}`}
              onClick={() => {
                globalStore.setFalseALL();
                globalStore.setRegisterForm(true);
                globalStore.setAuthForm(true);
                globalStore.setAuthFooterForm(true);
              }}
            >
              <p>Присоединиться</p>
            </div>
          </div>
        )}
      </div>
      <hr className={style.hr} />
    </header>
  );
};

export default observer(HeaderForm);

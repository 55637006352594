import $api from '../http'
import {AxiosResponse} from 'axios'
import { Profile } from '../models/Profile'

export default class ProfileService {
    static async getProfile(): Promise<AxiosResponse<Profile>> {
        return $api.get<Profile>(`/get-profile`);
    }

    static async uploadImg(img: string): Promise<void> {
        return $api.post(`/upload-img`, {img});
    }

    static async uploadAbout(about: string): Promise<void> {
        return $api.post(`/upload-about`, {about});
    }

    static async getImg(): Promise<AxiosResponse<string>> {
        return $api.get(`/get-img`);
    }
}

import { FC } from "react";
import { observer } from "mobx-react-lite";

import Layout from "../Layout";

//import style from "../../css/footer_css/FooterForm.module.css";

const WebForm: FC = () => {

  return (
    <Layout>
      Web
    </Layout>
  );
};

export default observer(WebForm);

import { FC } from "react";
import { observer } from "mobx-react-lite";

import Layout from "../Layout";
import MagicRandForm from "./revers_task_components/MagicRandForm"

const ReverseForm: FC = () => {

    return (
      <Layout>
        <MagicRandForm />
      </Layout>
    );
};

export default observer(ReverseForm);

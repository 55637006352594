import { makeAutoObservable } from "mobx";

export default class GlobalStore {

    isAboutForm: boolean = (false)
    isAuthLoading: boolean = (false)
    isAuthForm: boolean = (false)
    isAuthFooterForm: boolean = (false)
    isCategoriesForm: boolean = (false)
    isCryptForm: boolean = (false)
    isEditingForm: boolean = (false)
    isMagicRandForm: boolean = (false)
    isNotFoundForm: boolean = (false)
    isRatingForm: boolean = (false)
    isRecoveryFrom: boolean = (false)
    isRegisterForm: boolean = (false)
    isReverseForm: boolean = (false)
    isProfileForm: boolean = (false)
    isWebForm: boolean = (false)

    constructor() {
        makeAutoObservable(this)
    }

    setFalseALL() {
        (Object.keys(this) as (keyof this)[]).forEach(key => {
            if (typeof this[key] === 'boolean') {
                this[key] = false as any;
            }
        });
    }

    setAboutForm(bool: boolean) {
        this.isAboutForm = bool;
    }

    setAuthLoading(bool: boolean){
        this.isAuthLoading = bool
    }

    setAuthForm(bool: boolean) {
        this.isAuthForm = bool;
    }

    setAuthFooterForm(bool: boolean) {
        this.isAuthFooterForm = bool;
    }

    setCategoriesForm(bool:boolean) {
        this.isCategoriesForm = bool;
    }

    setCryptForm(bool:boolean) {
        this.isCryptForm = bool;
    }

    setEditingForm(bool: boolean) {
        this.isEditingForm = bool;
    }

    setMagicRandForm(bool: boolean) {
        this.isMagicRandForm = bool;
    }

    setNotFoundForm(bool: boolean) {
        this.isNotFoundForm = bool;
    }

    setRatingForm(bool: boolean) {
        this.isRatingForm = bool;
    }

    setRecoveryForm(bool: boolean) {
        this.isRecoveryFrom = bool;
    }

    setRegisterForm(bool: boolean) {
        this.isRegisterForm = bool;
    }

    setReverseForm(bool: boolean) {
        this.isReverseForm = bool;
    }

    setProfileForm(bool: boolean) {
        this.isProfileForm = bool;
    }

    setWebForm(bool: boolean) {
        this.isWebForm = bool
    }

}
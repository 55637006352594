import React from "react";
import { observer } from "mobx-react-lite";
import styles from "../../../css/task_css/revers_task_css/MagicRand.module.css";

const MagicRandForm: React.FC = () => {
  return (
    <div className={styles.mainTop}>
      <div className={styles.mainTopLeft}>
        <p>Reverse Engineering and PWN</p>
        <div className={styles.mtll}>
          <p>Магия псевдослучайности</p>
          <div style={{ display: "flex", gap: "4px" }}>
            <p>
              Уровень сложности: <span style={{ color: "#FF0000" }}>Elite</span>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.mainContent}>
        <div
          style={{
            display: "flex",
            borderImage: "linear-gradient(#FF1538, #6715FF, #15FF67) 30",
            borderWidth: "2px",
            borderStyle: "solid",
            marginTop: "16px",
          }}
        >
          <p style={{ padding: "12px" }}>
            В древнем королевстве магов и заклинаний существовал свиток с
            загадочным кодом. Этот код был защищён магией псевдослучайности и
            запутанного вывода, и никто не мог его разгадать. Ты — храбрый
            искатель приключений, готовый принять вызов. Тебе предстоит пройти
            через хитросплетения кода и обойти все ловушки, чтобы найти заветный
            флаг, спрятанный за замаскированным посланием. Задача: Изучи код,
            разгадай его алгоритмы и найди способ обойти защиты, чтобы получить
            доступ к флагу. Каждая строка кода важна, каждая деталь может быть
            ключом к успеху. Стань великим магическим декодировщиком и войди в
            историю! Вперед, приключение ждет тебя!
          </p>
          <div
            style={{
              textAlign: "center",
              alignContent: "center",
              minWidth: "max-content",
              borderImage: "linear-gradient(#FF1538, #6715FF, #15FF67) 30",
              borderWidth: "0 0 0 2px",
              borderStyle: "solid",
              padding: "50px",
            }}
          >
            <p>+10 к опыту</p>
          </div>
        </div>

        <div
          className={styles.mainBot}
          style={{ width: "100%", marginTop: "40px" }}
        >
          <div className={styles.btnsPrsd}>
            <div
              className={styles.btnPsrd}
              style={{ background: "red", color: "white" }}
            >
              <div>Скачать</div>
            </div>
            <div
              className={styles.btnPsrd}
              style={{ background: "#8000FF", color: "white" }}
            >
              <div >Подсказка</div>
            </div>
            <div
              className={styles.btnPsrd}
              style={{ background: "#80FF00", color: "black" }}
            >
              <div>Решение</div>
            </div>
          </div>
          <form className={styles.flagForm}>
            <label htmlFor="flag">flag:</label>
            <input
              className={styles.flagInput}
              type="text"
              id="flag"
              name="flag"
              maxLength={64}
              placeholder="RuToor{*********************}"
              required
            />
            <input
              className={styles.flagBtn}
              type="submit"
              value="Проверить флаг"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default observer(MagicRandForm);

import { FC } from "react";
import style from "../../css/info_css/AboutForm.module.css";


const AboutForm: FC = () => {
    return (
      <div className={style.main_head}>
        <h1 className={style.abouttext}>
          Твой Путь в Мир Информационной безопасности
        </h1>
        <br />
        <div className={style.abouttext2}>
          &thinsp;&thinsp;О нас:
          <br />
        </div>
        <div className={style.abouttext3}>
          &thinsp;&thinsp;&thinsp;&thinsp; • Консалтинг: Мы предоставляем
          экспертные консультации для разработки стратегий защиты информации,
          учитывая уникальные потребности вашего бизнеса.
          <br />
          &thinsp;&thinsp;&thinsp;&thinsp; • Аудит безопасности: Наши
          специалисты проведут тщательный анализ вашей IT-инфраструктуры и
          предложат решения для повышения её защищённости.
          <br />
          &thinsp;&thinsp;&thinsp;&thinsp; • Внедрение систем безопасности: Мы
          поможем вам интегрировать передовые технологии и решения для
          обеспечения комплексной защиты данных.
          <br />
          <br />
        </div>
        <div className={style.abouttext2}>
          &thinsp;&thinsp;&thinsp;&thinsp;Почему Выбирают Нас?
          <br />
        </div>
        <div className={style.abouttext3}>
          &thinsp;&thinsp;&thinsp;&thinsp; • Профессионализм: Наша команда
          состоит из опытных специалистов с многолетним опытом в сфере
          информационной безопасности.
          <br />
          &thinsp;&thinsp;&thinsp;&thinsp; • Актуальность: Мы постоянно
          обновляем наши курсы и услуги в соответствии с последними тенденциями
          и угрозами в мире кибербезопасности.
          <br />
          &thinsp;&thinsp;&thinsp;&thinsp; • Поддержка: Мы всегда готовы оказать
          поддержку и помочь вам решить любые вопросы, связанные с защитой
          информации.
        </div>
      </div>
    );
}

export default AboutForm;
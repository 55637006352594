import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Context } from "../../index";

import Layout from "../Layout";

import easy from "../../img/main_img/web.svg";

import style from "../../css/categories_css/CardsForm.module.css";

const CategoriesForm: FC = () => {
  const { globalStore } = useContext(Context);

  return (
    <Layout>
      <div>
        <div className="main_top">
          <div className={style.main_content}>
            <h3>Выбор сложности</h3>
          </div>
        </div>
        <hr />
        <div className="main_content">
          <div className={style.rooms}>
            <div className={style.roomez}>
              <img src={easy} alt="img" />
              <div className={style.room_text}>
                <h6 style={{ color: "#24FF14" }}>ScriptKiddie</h6>
                <p>
                  В сложности “ScriptKiddie” свойственны задачи лёгкого уровня.
                  Особенно хорошо подходят для начинающих.
                </p>
              </div>
            </div>
            <div className={style.roommed}>
              <img src={easy} alt="img" />
              <div className={style.room_text}>
                <h6 style={{ color: "#FFF200" }}>Seasoned Hacker</h6>
                <p>
                  В сложности “Seasoned Hacker” требуется использовать
                  умственные способности и внимательность.
                </p>
              </div>
            </div>
            <div
              className={style.roomhrd}
              onClick={() => {
                globalStore.setFalseALL();
                globalStore.setMagicRandForm(true);
              }}
            >
              <img src={easy} alt="img" />
              <div className={style.room_text}>
                <h6 style={{ color: "#FF0000" }}>Elite</h6>
                <p>
                  Наверное, это самые сложные задачи, которые вы бы могли
                  встретить. Очень хорошо подходят для получения опыта перед
                  экзаменацией.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(CategoriesForm);

import { FC, useContext } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import Layout from "../Layout";
import EditingForm from "./EditingForm";
import IsActiveForm from "./IsActiveForm";

import avatar from "../../img/main_img/avatar.svg";
import telegramImg from "../../img/main_img/telegram.svg";

import style from "../../css/profile_css/ProfileForm.module.css";

const ProfileForm: FC = () => {
  const { authStore, globalStore, profileStore } = useContext(Context);

  return (
    <Layout>
      <div className={style.main}>
        {authStore.user.isActive ? (
          <div className={style["main-in"]}>
            {globalStore.isEditingForm ? (
              <EditingForm />
            ) : (
              <div className={style.left}>
                <div className={style.ava}>
                  <img
                    src={profileStore.isMyImg ? profileStore.isMyImg : avatar}
                    className={style.avatarImg}
                    alt="avatar"
                    onClick={() => globalStore.setProfileForm(true)}
                  />
                </div>
                <div className={style["profile-stats"]}>
                  <p className={style.username_sidebar}>
                    {authStore.user.username}
                  </p>
                  <p>1 уровень</p>
                  <div className={style.progresbar}></div>
                  <p>120 / 180 XP</p>
                </div>
                <div className={style.calendar}>
                  <div className={style.dow}>
                    <p>Пн</p>
                    <p>Вт</p>
                    <p>Ср</p>
                    <p>Чт</p>
                    <p>Пт</p>
                    <p>Сб</p>
                    <p>Вс</p>
                  </div>
                  <div className={style["cal-in"]}>
                    <div className={style.mounths}>
                      <p>Июнь</p>
                      <p>Июль</p>
                      <p>Август</p>
                    </div>
                    {[...Array(7)].map((_, i) => (
                      <div className={style.str} key={i}>
                        {[...Array(10)].map((_, j) => (
                          <div className={style.cal} key={j}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  className={style["save-btn"]}
                  type="submit"
                  onClick={() => globalStore.setEditingForm(true)}
                >
                  <p>Редактировать</p>
                </button>
                {profileStore.profile.about && (
                  <div className={style.about}>
                    <p>Обо мне</p>
                    <div className={style["about-in"]}>
                      <p>{profileStore.profile.about}</p>
                    </div>
                  </div>
                )}
                <div className={style.contacts}>
                  <p>Контактная информация</p>
                  <a href="https://t.me/+DUjRFYJXcVY1Y2Ni" className={style.a}>
                    <img src={telegramImg} alt="tg" /> Телеграмм
                  </a>
                </div>
              </div>
            )}
            <div className={style.center}>
              <div className={style.medals}>
                <p>Медали</p>
                <div className={style["medals-in"]}></div>
              </div>
              <div className={style["last-tasks"]}>
                <p>Последние прохождения</p>
                <div className={style["lt-in"]}></div>
              </div>
              <div className={style["last-activity"]}>
                <p>Недавняя активность</p>
                <div className={style["la-in"]}>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <IsActiveForm />
        )}
      </div>
    </Layout>
  );
};

export default observer(ProfileForm);

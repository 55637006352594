import React from "react";
import { observer } from "mobx-react-lite";
import HeaderForm from "./header_component/HeaderForm";
import FooterForm from "./footer_component/FooterForm";
import styles from "../css/Layout.module.css"; 

type LayoutProps = {
  children: React.ReactNode; 
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <HeaderForm />
      <div className={styles.content}>
        {children} {}
      </div>
      <FooterForm />
    </div>
  );
};

export default  observer(Layout);

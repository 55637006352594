import { FC } from "react";
import { observer } from "mobx-react-lite";

import Layout from "../Layout";

import style from "../../css/profile_css/isActiveForm.module.css";

import mask from "../../img/profile_img/maskGroup.svg";

const NotFoundForm: FC = () => {
  return (
    <Layout>
      <div className={style.container}>
        <p className={style.text1}>Опаньки...!</p>
        <p className={style.text2}>404 Страница не найдена...</p>
      </div>
      <img className={style.img} src={mask} alt="img" />
    </Layout>
  );
};

export default observer(NotFoundForm);

import { FC, useContext, useEffect } from "react";
import { Context } from "../../index";

import Layout from "../Layout";
import AboutForm from "../info_components/AboutForm";

import style from "../../css/main_css/MainForm.module.css";
import "../../css/main_css/Normalize.css";

import web from "../../img/main_img/web.svg";
import crypto from "../../img/main_img/crypto.svg";
import reverse from "../../img/main_img/reverse.svg";
import login_background from "../../img/main_img/main_back.svg";
import { observer } from "mobx-react-lite";


const MainForm: FC = () => {
  const {globalStore } = useContext(Context);

  useEffect(() => {
    globalStore.setRegisterForm(false);
  }, [globalStore]);

  return (
    <Layout>
      <div className={style.imageWrapper}>
        <img
          className={style.background}
          src={login_background}
          alt="background"
        />
      </div>

      {globalStore.isAboutForm ? (
        <AboutForm />
      ) : (
        <div className={style.main}>
          <div className={style.main_head}>
            <h1>Стань мастером киберзащиты с RuToor.</h1>
            <h2>
              Информационная безопасность - это ключевой аспект современного
              цифрового мира. В RuToor мы предлагаем комплексные решения и
              образовательные программы, которые помогут вам защитить данные и
              системы от различных угроз.
            </h2>
          </div>

          <div className={style.main_content}>
            <p>Направления наших комнат для изучения:</p>
            <div className={style.rooms}>
              <div onClick={() => globalStore.setWebForm(true)}>
                <div className={style.room}>
                  <img src={web} alt="img" />
                  <div className={style.room_text}>
                    <h6>Web</h6>
                    <p>
                      В категории WEB вы столкнетесь с задачами, связанными с
                      безопасностью веб-приложений и веб-технологий. Здесь вам
                      предстоит использовать свои знания и навыки для анализа,
                      исследования и эксплуатации уязвимостей в различных
                      веб-сервисах и приложениях.
                    </p>
                  </div>
                </div>
              </div>
              <div onClick={() => globalStore.setReverseForm(true)}>
                <div className={style.room}>
                  <img src={reverse} alt="img" />
                  <div className={style.room_text}>
                    <h6>Reverse/Pwn</h6>
                    <p>
                      Добро пожаловать в древнее королевство RuToor, где магия и
                      технологии переплелись в одно целое. Здесь, среди
                      таинственных ROP и волшебных переполнений, скрыты великие
                      загадки и сокровища, охраняемые магическими преградами и
                      защитными механизмами.
                    </p>
                  </div>
                </div>
              </div>
              <div onClick={() => globalStore.setCryptForm(true)}>
                <div className={style.room}>
                  <img src={crypto} alt="img" />
                  <div className={style.room_text}>
                    <h6>Crypto/Forensic</h6>
                    <p>
                      В категории Crypto/Forensic вас ждут задания, связанные с
                      криптографией и цифровой криминалистикой. <br /> Вам
                      предстоит разбираться в шифрах и криптографических
                      алгоритмах, а также анализировать данные и восстанавливать
                      информацию из различных цифровых источников.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default observer(MainForm);

import { FC, useContext, useRef, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import avatar from "../../img/main_img/avatar.svg";
import telegramImg from "../../img/main_img/telegram.svg";

import style from "../../css/profile_css/ProfileForm.module.css";

const EditingForm: FC = () => {
  const { authStore, globalStore, profileStore } = useContext(Context);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; 
      textarea.style.height = `${textarea.scrollHeight}px`; 
    }
  };

    const handleAboutMeChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      profileStore.setCheckAboutChange(true)
      adjustTextareaHeight();
    };

    useEffect(() => {
      profileStore.setMyAbout(profileStore.profile.about)
      adjustTextareaHeight();
    }, []);

    return (
      <div className={style.left}>
        <input
          type="file"
          accept="image/*"
          onChange={(event) => profileStore.handleFileChange(event)}
          style={{ display: "none" }}
          id="fileInput"
          ref={(fileInput) =>
            fileInput && fileInput.setAttribute("multiple", "false")
          }
        />

        <div
          className={style.ava}
          onClick={() => {
            document?.getElementById("fileInput")?.click();
          }}
        >
          <img
            src={profileStore.isMyImg ? profileStore.isMyImg : avatar}
            className={style.avatarImgEditing}
            alt="avatar"
            onClick={() => globalStore.setProfileForm(true)}
          />
        </div>
        <div className={style["profile-stats"]}>
          <p className={style.username_sidebar}>{authStore.user.username}</p>
        </div>
        <button
          className={style["save-btn"]}
          type="submit"
          onClick={async () => {
            await profileStore.changeProfile();
            globalStore.setEditingForm(false);
          }}
        >
          <p>Сохранить</p>
        </button>
        <div className={style.about}>
          <p>Обо мне</p>
          <textarea
            ref={textareaRef}
            className={style["about-in-edit"]}
            onChange={(e) => {
              profileStore.setMyAbout(e.target.value);
              handleAboutMeChange(e);
            }}
            value={profileStore.isMyAbout}
            maxLength={254}
            rows={1}
          />
        </div>
        <div className={style.contacts}>
          <p>Контактная информация</p>
          <a href="https://t.me/+DUjRFYJXcVY1Y2Ni" className={style.a}>
            <img src={telegramImg} alt="tg" /> Телеграмм
          </a>
        </div>
      </div>
    );
};

export default observer(EditingForm);

import { FC, useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import logo from "../../img/logo_img/logo.svg";

import style from "../../css/auth_css/authForm.module.css";

const RecoveryForm: FC = () => {
  const { authStore, globalStore } = useContext(Context);

  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");

    useEffect(() => {
        globalStore.setAuthFooterForm(true);
    })

  return (
    <div className={style.login}>
      <div className={`${style.logo_name} ${style.flex}`}>
        <div>
          <img
            className={style.logo}
            onClick={() => {
              globalStore.setFalseALL();
              authStore.setErrorMessage("");
            }}
            src={logo}
            alt="logo"
          />
        </div>
        <p className={style.text}>Изменение пароля</p>
      </div>

      <div className={style.login_container}>
        <form className={`${style.form} ${style.form_login}`}>
          {!authStore.isSuccessSend && (
            <div>
              <div className={style.form_label}>
                <label>Пароль</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  maxLength={50}
                  placeholder="Новый пароль"
                />
              </div>

              <div className={style.form_label}>
                <label>Повторите пароль</label>
                <input
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword}
                  type="password"
                  placeholder="Повторите пароль"
                  maxLength={50}
                />
              </div>

              <button
                className={style.change_btn}
                type="button"
                onClick={async () => {
                  await authStore.changePassword(password, repeatPassword);
                }}
              >
                Изменить пароль
              </button>
            </div>
          )}

          {authStore.isSuccessSend && (
            <h2 className={style.success_text}>Пароль успешно изменён</h2>
          )}

          {authStore.isSuccessSend && (
            <button
              className={style.register_btn}
              type="button"
              onClick={() => {
                authStore.setSuccessSend(false);
                globalStore.setRecoveryForm(false)
                authStore.setErrorMessage("");
              }}
            >
              Авторизоваться
            </button>
          )}

          {authStore.errorMessage && (
            <div
              className={`${style.error} ${
                authStore.isShake ? style.shake_animation : ""
              }`}
            >
              {authStore.errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default observer(RecoveryForm);

import { makeAutoObservable } from "mobx";
import ProfileService from "../service/ProfileService";
import { Profile } from "../models/Profile";

export default class AuthStore {
    profile = {} as Profile

    ALLOWED_FILE_FORMATS = ["jpg", "jpeg", "png"];
    MAX_FILE_SIZE = 1024 * 1024

    isCheckImgChang: boolean = false
    isCheckAboutChang: boolean = false
    isShake: boolean = false

    isMyAbout: string = ''
    isMyImg: string = ''
    errorMessage: string = ''
    selectFile: File | null = null

    constructor() {
        makeAutoObservable(this)
    }

    setCheckAboutChange(bool : boolean) {
        this.isCheckAboutChang = bool
    }

    setCheckImgChange(bool : boolean) {
        this.isCheckImgChang = bool
    }

    setMyAbout(about: string) {
        this.isMyAbout = about
    }

    setMyImg(myImg: string) {
        this.isMyImg = myImg
    }

    setProfile(profile: Profile) {
        this.profile = profile
    }

    async changeProfile () {
        try {
            if (this.isCheckImgChang)
                await ProfileService.uploadImg(this.isMyImg)
            if (this.isCheckAboutChang)
                await ProfileService.uploadAbout(this.isMyAbout)
            
            this.profile.about = this.isMyAbout
        } catch(e: any) {
            console.log(e.response?.data?.message)
        }
        finally {
            this.setCheckImgChange(false)
            this.setCheckAboutChange(false)
        }
    }

    async getImg() {
        try {
            const response = await ProfileService.getImg()
            if (response.data[0] !== null) {
                this.setMyImg(response.data)
            }

        } catch(e: any) {
            console.log(e.response?.data?.message)
        }
    }


    async getProfile() {
        try {
            const response = await ProfileService.getProfile()
            this.setProfile(response.data)
        } catch(e: any) {
            console.log(e.response?.data?.message)
        }
    }

    isFileValid (file: File): boolean {
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split(".").pop();

        if (!fileExtension || !this.ALLOWED_FILE_FORMATS.includes(fileExtension)) {
            alert("Выберите файл в формате jpg, jpeg или png.");
            return false;
        }

        return true;
    };

    async handleFileChange(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const file = event.target.files?.[0];

        if (file) {
            if (!this.isFileValid(file)) {
                return;
            }

            if (file.size > this.MAX_FILE_SIZE) {
                alert("Выберите файл размером менее 1 МБ.");
            } else {
                const fileBytes = await this.readFileAsBase64(file);
                this.setMyImg(fileBytes);
                this.setCheckImgChange(true)
            }
        }
    }

    readFileAsBase64(file: File): Promise<string>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }    
}